import Utils from "../utils"
import { type ISelectOption } from "../QuestionnaireUtils"
import {clone} from "@avvoka/shared";


const reverse = (object: { [key: string]: string }): { [key: string]: string } => {
  const ret: { [key: string]: string } = {}
  for (const key in object) {
    ret[object[key]] = key
  }
  return ret
}

class AvvParser {
  static MAP: { [key: string]: string } = {
    "(": "leftParenthesis",
    ")": "rightParenthesis",
    '"': "quote",
    "'": "simpleQuote",
    "[": "leftBracket",
    "]": "rightBracket",
    "{": "leftCurly",
    "}": "rightCurly",
    "#": "hashtag",
    "?": "questionMark",
    "&": "ampersand",
    "<": "leftSharp",
    ">": "rightSharp",
    "`": "backTick",
    "\\": "backSlash",
    "|": "007C",
  }

  static REVERSE_MAP = reverse(AvvParser.MAP)
  static MINIMUM_LENGTH = Object.keys(AvvParser.REVERSE_MAP).reduce(function (accumulator, currentValue) {
    return accumulator.length <= currentValue.length ? accumulator : currentValue
  }).length

  static DECODE_REGEX = new RegExp(/\_Avv(.*?)\_aVV/, "g")
  static ENCODE_REGEX = new RegExp(
    Object.keys(AvvParser.MAP)
      .map(key => `\\${key}`)
      .join("|"),
    "g",
  )

  static decode = <T> (data: T): T => {
    if (typeof data === "string") {
      /** 8 - Because (_Avv + _aVV) + MINIMUM_LENGTH (the lower length in map object) */
      if (data.length <= 8 + AvvParser.MINIMUM_LENGTH) return data
      return data.replace(AvvParser.DECODE_REGEX, (fullMatch, match) => {
        return AvvParser.REVERSE_MAP[match] || fullMatch
      }) as any
    }
    return data
  }

  static encode = <T> (data: T): T => {
    if (typeof data === "string") {
      if (data.length <= 0) return data
      return data.replace(AvvParser.ENCODE_REGEX, (match: string) => {
        const value = AvvParser.MAP[match] || match
        return `_Avv${value}_aVV`
      }) as any
    }
    return data
  }

  static encodeHTML = (html) => {
    const singleQuote = AvvParser.encode("'")
    return html.replace(/'/g, singleQuote).replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;").replace(new RegExp(String.fromCharCode(160), "g"), "&nbsp;");
  }

  static decodeHTML = (html) => {
    return AvvParser.decode(html).replace(/&amp;/g, "&").replace(/&gt;/g, ">").replace(/&lt;/g, "<").replace(/&quot;/g, '"').replace(/&#39;/g, "'").replace(/&nbsp;/g, String.fromCharCode(160));
  }

  static unicode = (string) => {
    return string.split('').map(el => {
      const hex = el.codePointAt(0).toString(16)
      return `u${"0000".substring(0, 4 - hex.length)}${hex}`
    }).join("-")
  }

  static deunicode = (string) => {
    return Array.from(string.matchAll(/u([a-f0-9]{4})/g))
      .map(e => e[1])
      .map(hex => String.fromCodePoint(parseInt(hex, 16)))
      .join('')
  }

  static SetupBuilder = class SetupBuilder {
    constructor(private readonly format: {
      BEGIN_MARK: string,
      END_MARK: string,
      append(content: string): void
    }) {
    }

    get BEGIN_MARK() {
      return this.format.BEGIN_MARK;
    }

    get END_MARK() {
      return this.format.END_MARK;
    }

    question(options) {
      if (!options) options = {}
      else options = clone(options)
      const desc = options.desc;
      const opts = options.opts;
      delete options.desc;
      delete options.opts;
      this.format.append(`${this.BEGIN_MARK}question [${AvvParser.encode(desc)}]${JSON.stringify({...options, ...opts})}${this.END_MARK}`);
      return this;
    }

    endSetup() {
      this.format.append(".end setup");
      return this.format;
    }
  }

  static QuestionnaireBuilder = class QuestionnaireBuilder {
    bInsideCondition = false
    constructor(private readonly format: {
      BEGIN_MARK: string,
      END_MARK: string,
      append(content: string): void
    }) {
    }

    get BEGIN_MARK() {
      return this.format.BEGIN_MARK;
    }

    get END_MARK() {
      return this.format.END_MARK;
    }

    get prefix() {
      return this.bInsideCondition ? "  " : ""
    }

    /*
      Returns avvoka format for basic (with attribute) or text (segment) questions
    */
    basic_format (question: Backend.Questionnaire.IQuestion) {
      const q = AvvParser.denormalize(Utils.deepCopy(question))
      this.format.append(`${this.prefix}${this.BEGIN_MARK}${q.type} ${q.att} [${q.desc}]${JSON.stringify(q.opts)}${this.END_MARK}`)
      return this
    }

    text_format (question: Backend.Questionnaire.IQuestion) {
      const q = AvvParser.denormalize(Utils.deepCopy(question))
      this.format.append(`${this.prefix}${this.BEGIN_MARK}${q.type} [${q.desc}]${JSON.stringify(q.opts)}${this.END_MARK}`)
      return this
    }

    /*
      Text shortcuts
    */
    label (q: Backend.Questionnaire.IQuestion) {
      return this.text_format(q);
    }

    section (q: Backend.Questionnaire.IQuestion) {
      return this.text_format(q);
    }

    /*
      Basic shortcuts
    */
    file_upload(q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    metadata(q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    checkbox (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    select (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    input (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    currency (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    date (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    number (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    listSelectDb (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    dependentList (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    open_select (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    multi_select (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    datasheets (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    yes_no (q: Backend.Questionnaire.IQuestion) {
      return this.basic_format(q)
    }

    endQuestionnaire() {
      this.format.append(".end questionnaire");
      return this.format;
    }

    condition(ast: string) {
      this.format.append(`${this.BEGIN_MARK}if {condition: |${ast}|}${this.END_MARK}`)
      this.bInsideCondition = true;
      return this;
    }

    endCondition() {
      this.bInsideCondition = false;
      this.format.append(`${this.BEGIN_MARK}end if${this.END_MARK}`)
      return this;
    }
  }

  static OperationsBuilder = class OperationsBuilder {
    constructor(private readonly format: {
      BEGIN_MARK: string,
      END_MARK: string,
      append(content: string): void
    }) {
    }


    get BEGIN_MARK() {
      return this.format.BEGIN_MARK;
    }

    get END_MARK() {
      return this.format.END_MARK;
    }

    basic_format(operationName, ast) {
      operationName = AvvParser.encode(operationName);
      this.format.append(`${this.BEGIN_MARK}${operationName} ${ast}${this.END_MARK}`);
      return this;
    }

    operation(op) {
      return this.basic_format(op.att, op.cond);
    }

    endOperations() {
      this.format.append(".end calculations")
      return this.format;
    }
  }

  static pBT = (tag) => `\`\\s?${tag}\\s*`
  static pVAR = `(.*?)\\s*`
  static pOPTS = `(\\{.*?\\})?`
  static pET = `\\s*\``
  static pLBL = `(\\[.*?\\])?`
  static pLBLNoParentheses = `\\[(.*?)\\]`

  static OPERATION_REGEX = new RegExp(AvvParser.pBT("") + AvvParser.pVAR + AvvParser.pOPTS + AvvParser.pET);
  static SETUP_REGEX = new RegExp(AvvParser.pBT("") + AvvParser.pVAR + AvvParser.pLBLNoParentheses + AvvParser.pOPTS + AvvParser.pET)
  static AVVFormat = class AvvokaFormat {
    BEGIN_MARK: string
    END_MARK: string
    format: string

    constructor() {
      this.BEGIN_MARK = "`";
      this.END_MARK = "`";
      this.format = "";
    }

    append(content) {
      this.format += `${content}\n`;
    }

    setup() {
      this.append(".setup");
      return new AvvParser.SetupBuilder(this);
    }

    get hasSetup() {
      return this.format.indexOf(".setup") !== -1
    }

    questionnaire(partyName) {
      this.append(`.questionnaire ${AvvParser.encode(partyName)}`);
      return new AvvParser.QuestionnaireBuilder(this);
    }

    get hasQuestionnaire() {
      return this.format.indexOf(".questionnaire") !== -1
    }

    operations() {
      this.append(".calculations");
      return new AvvParser.OperationsBuilder(this)
    }

    get hasOperations() {
      return this.format.indexOf(".calculations") !== -1
    }

    static store(template) {
      const questionnaires = [];
      const questions: Backend.Questionnaire.IQuestion[] = [];
      const operations = [];
      const setup = {
        template_question: {
          desc: "Provide your #{att}",
          type: "input",
          party: undefined,
          opts: {
            required: false,
            default: "",
            dateLocale: "en_us",
            dateFormat: "d/m/Y"
          },
        }
      }

      let qParty = "";
      let bInsideQuestionnaire = false;
      let bInsideQCondition = false
      let bInsideOperations = false
      let bInsideSetup = false
      let bCondition = null;

      const generateUUID = () => {
        let uuid = '',
          i,
          random;
        for (i = 0; i < 32; i++) {
          random = (Math.random() * 16) | 0;

          if (i === 8 || i === 12 || i === 16 || i === 20) {
            uuid += '-';
          }
          // tslint:disable-next-line:no-bitwise
          uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
        }
        return uuid as any;
      }

      const qBody = (line) => {
        if (line.startsWith("`if")) {

          bInsideQCondition = true;
          `if {condition: |{'ast':{'And':[{'Equals':[{'Att':'LENDERS'},{'String':'Syndicated'}]},{'Equals':[{'Att':'LOAN TRANSACTION'},{'String':'Yes'}]}]}}|}`
          /** match: `if {$COND$: |$AST$|}` */
          const match = line.match(/`if \{(\w+): \|(.*)\|(,"data-id":"\d*")?\}`/);
          bCondition = match[2];
          return;
        }

        if (bInsideQCondition) {
          if (line.startsWith("`end")) {
            bInsideQCondition = false;
            return;
          }
        }

        /** match: `$TYPE$ $ATTR$ [$DESC$]{$OPTIONS$}` */
        let match = line.match(/`(\w+) ([^\[]+) \[(.*?)\](.*)`/);
        if (match != null) {
          questions.push({
            party: qParty,
            type: match[1],
            att: match[2],
            desc: match[3],
            opts: JSON.parse(match[4].replace(/\\/g, "\\\\")),
            cond: bInsideQCondition ? bCondition : undefined,
            uuid: generateUUID(),
          })
          return;
        }

        /** match: `$TYPE$ [$DESC$]{$OPTIONS$}` */
        match = line.match(/`(\w+) \[(.*?)\](.*)`/)
        if (match != null) {
          questions.push({
            party: qParty,
            type: match[1],
            desc: match[2],
            opts: JSON.parse(match[3].replace(/\\/g, "\\\\")),
            cond: bInsideQCondition ? bCondition : undefined,
            uuid: generateUUID(),
          })
          return;
        }

        questions.forEach((question) => AvvParser.normalize(question))

        if (line.startsWith(".end")) {
          bInsideQuestionnaire = false;
        }
      }

      /** match: `$ATTR$ {$COND$}` */
      const operationsBody = (line) => {

        const match = line.match(AvvParser.OPERATION_REGEX);
        if (match != null) {
          operations.push({
            att: AvvParser.decode(match[1]),
            cond: match[2],
            uuid: generateUUID()
          })
        }

        if (line.startsWith(".end")) {
          bInsideOperations = false;
        }
      }

      const setupBody = (line) => {
        const match = line.replace(/\[+/g, "[").replace(/\]+/g, "]").match(AvvParser.SETUP_REGEX)
        if (match != null) {
          if (match[1] === "question") {
            setup.template_question.desc = AvvParser.decode(match[2])
            const opts = JSON.parse(match[3].replace(/\\/g, "\\\\"))
            setup.template_question.type = opts.type
            setup.template_question.party = opts.party
            setup.template_question.opts = opts;
            setup.template_question.opts.dateLocale ??= "en_us";
            setup.template_question.opts.dateFormat ??= "MMMM d, yyyy";
            delete opts.type;
            delete opts.party;
          }
        }

        if (line.startsWith(".end")) {
          bInsideSetup = false;
        }
      }

      const data = template.split("\n")
      for (const line of data) {
        if (!bInsideQuestionnaire) {
          if (line.startsWith(".questionnaire")) {
            questionnaires.push(AvvParser.decode(line.slice(".questionnaire ".length)))
            qParty = questionnaires[questionnaires.length - 1]
            bInsideQuestionnaire = true;
            continue;
          }
        }

        if (bInsideQuestionnaire) {
          qBody(line);
          continue;
        }

        if (!bInsideOperations) {
          if (line.startsWith(".calculations")) {
            bInsideOperations = true;
            continue;
          }
        }

        if (bInsideOperations) {
          operationsBody(line);
          continue;
        }

        if (!bInsideSetup) {
          if (line.startsWith(".setup")) {
            bInsideSetup = true;
            continue;
          }
        }

        if (bInsideSetup) {
          setupBody(line);

        }
      }

      if(setup.template_question.party == null) {
        setup.template_question.party = questionnaires[0]
      }

      return {
        questionnaires,
        questions,
        operations,
        question_template: setup.template_question,
      }
    }
  }

  // Decode question in place
  static normalize (question: Backend.Questionnaire.IQuestion): Backend.Questionnaire.IQuestion {
    const type = question.type
    const opts = (question.opts ||= {})

    question.att = AvvParser.decode(question.att)
    question.desc = AvvParser.decode(question.desc)

    if (type === 'label') {
      opts.custom = true

      if (opts.labelType === 'Title') question.desc = `# ${question.desc}`
      else if (opts.labelType === 'Subtitle') question.desc = `## ${question.desc}`
    } else if (type === 'select' || type === 'open_select' || type === 'multi_select') {
      if (Array.isArray(opts.selectOptions)) {
        opts.selectOptions.forEach((option: ISelectOption) => {
          option.value = AvvParser.decode(option.value)
          option.label = AvvParser.decode(option.label)
        })
      } else if (opts.selectOptions) {
        opts.selectOptions = AvvParser.decode(String(opts.selectOptions)).split(';').map((value: string) => ({ value, label: value }))
      } else {
        opts.selectOptions = []
      }

      if (Array.isArray(opts.collectOptions)) {
        // Update collect options into new format where they are included as part of selectOptions with specified type = 'collect'
        opts.collectOptions.forEach((attribute: string) => {
          const value = AvvParser.decode(attribute)
          opts.selectOptions.push({ value, label: '', type: 'collect' })
        })

        delete opts.collectOptions
      }
    } else if (type === 'yes_no') {
      opts.options = opts.options.map((option: ISelectOption) => ({
        value: AvvParser.decode(option.value),
        label: AvvParser.decode(option.label)
      }))
    } else if (type === 'checkbox') {
      if (opts.checkedValue) opts.checkedValue = AvvParser.decode(opts.checkedValue)
      if (opts.uncheckedValue) opts.uncheckedValue = AvvParser.decode(opts.uncheckedValue)
      if (opts.checkedLabel) opts.checkedLabel = AvvParser.decode(opts.checkedLabel)
      if (opts.uncheckedLabel) opts.uncheckedLabel = AvvParser.decode(opts.uncheckedLabel)
    }

    if (opts.default) opts.default = AvvParser.decode(String(opts.default))
    if (opts.placeholder) opts.placeholder = AvvParser.decode(String(opts.placeholder))

    return question
  }

  // Encode question in place
  static denormalize (question: Backend.Questionnaire.IQuestion): Backend.Questionnaire.IQuestion {
    const type = question.type
    const opts = (question.opts ||= {})

    question.att = AvvParser.encode(question.att)
    question.desc = AvvParser.encode(question.desc)

    if (type === 'select' || type === 'open_select' || type === 'multi_select') {
      if (Array.isArray(opts.selectOptions)) {
        opts.selectOptions.forEach((option: ISelectOption) => {
          option.value = AvvParser.encode(option.value)
          option.label = AvvParser.encode(option.label || option.value)
        })
      } else if (opts.selectOptions) {
        opts.selectOptions = AvvParser.encode(String(opts.selectOptions)).split(';').map((value) => ({ value, label: value }))
      } else {
        opts.selectOptions = []
      }
    } else if (type === 'yes_no') {
      opts.options = opts.options.map((option: ISelectOption) => ({
        value: AvvParser.encode(option.value),
        label: AvvParser.encode(option.label)
      }))
    } else if (type === 'checkbox') {
      if (opts.checkedValue) opts.checkedValue = AvvParser.encode(opts.checkedValue)
      if (opts.uncheckedValue) opts.uncheckedValue = AvvParser.encode(opts.uncheckedValue)
      if (opts.checkedLabel) opts.checkedLabel = AvvParser.encode(opts.checkedLabel)
      if (opts.uncheckedLabel) opts.uncheckedLabel = AvvParser.encode(opts.uncheckedLabel)
    }

    if(opts.default) opts.default = AvvParser.encode(String(opts.default))
    if(opts.placeholder) opts.placeholder = AvvParser.encode(String(opts.placeholder))

    return question
  }
}

declare global {
  interface Window {
    AvvParser: typeof AvvParser;
  }
}
window.AvvParser = AvvParser
export default AvvParser
